<template>
  <div class="page">
    <div class="page-title">
      <img @click="$router.push('/')" src="@/assets/images/back.svg">
      {{$route.meta.routeTitle}}
    </div>

    <img class="poster" src="@/assets/images/rules-tko.jpg">

    <div class="paragr">В соответствии с Федеральным законом № 89-ФЗ «Об отходах производства и потребления» транспортирование, обработка, утилизация, обезвреживание, захоронение твердых коммунальных отходов на территории Республики Башкортостан обеспечиваются региональными операторами в соответствии с региональной программой в области обращения с отходами и территориальной схемой обращения с отходами.</div>
    <div class="paragr">Потребители осуществляют складирование твердых коммунальных отходов в местах, определенных договором: в контейнеры, бункеры, расположенные на контейнерных площадках.</div>
    <div class="paragr">Крупногабаритные отходы (КГО): старая мебель, отходы от мелкого текущего ремонта (обои, плинтус, бой керамической плитки) – выносятся на специальную площадку в вашем дворе (населенном пункте) и вывозятся в рамках договора с региональным оператором.</div>
    <div class="paragr">Строительные отходы (не относятся к ТКО): битый кирпич, отходы бетона, отходы от сноса стен, разборки крыш, опор и оснований, железобетона и других отходов ремонта и реконструкции, спил деревьев, обрезанные ветки– вывозятся по отдельному договору.</div>
    <div class="paragr">Навоз, помет и другие органические вещества, образующиеся в результате содержания сельскохозяйственных животных (не относятся к ТКО), помещать в контейнеры с ТКО нельзя! Вывозятся отдельно.</div>
  </div>
</template>

<script setup></script>

<style scoped lang="scss">
.page-title{
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
  padding-left: 0;
  img{
    margin-right: 10px;
    margin-top: 3px;
    cursor: pointer;
  }
}
.poster{
  border-radius: 16px;
  margin-bottom: 30px;
  max-width: 100%;
}
.paragr{
  margin-bottom: 30px;
  font-size: 16px;
  line-height: 28px;
  font-family: 'TT Norms Pro Regular';
}
</style>
